import React from "react";

import ListItem from "./ListItem";

import styles from "./List.module.scss";



export default function List({ changeActiveImg }) {

  const dataArray = [
    //Linki GG auto Rzeszow
    // {
    //   id: "0",
    //   name: "Audi",
    //   iconClass: "audi",
    //   link: "https://www.audicentrumrzeszow.audi.pl"
    // },
    // {
    //   id: "1",
    //   name: "SEAT",
    //   iconClass: "seat",
    //   link: "https://autorud.seat-auto.pl/"
    // },
    // {
    //   id: "2",
    //   name: "Skoda",
    //   iconClass: "skoda",
    //   link: "http://www.skoda.autorud.pl/"
    // },
    // {
    //   id: "3",
    //   name: "Volkswagen",
    //   iconClass: "vw",
    //   link: "https://autorud-rzeszow.dealer.volkswagen.pl/"
    // },
    // {
    //   id: "4",
    //   name: "Samochody używane",
    //   iconClass: "used",
    //   link: "https://porownywarka.autorud.rzeszow.pl/samochody-uzywane"
    // },
    // {
    //   id: "5",
    //   name: "Części samochodowe",
    //   iconClass: "parts",
    //   link: "https://eparts4you.pl/"
    // }

,
    // {
    //   id: "6",
    //   name: "Ubezpieczenia",
    //   iconClass: "ubezpieczenia",
    //   link: "https://eparts4you.pl/"
    // }

     //Linki Dla GG Auto lublin 
    {
      id: "0",
      name: "Audi",
      iconClass: "audi",
      link: " https://www.audicentrumlublin.audi.pl/pl.html"
    },
    {
      id: "1",
      name: "SEAT",
      iconClass: "seat",
      link: "#"
    },
    {
      id: "2",
      name: "Skoda",
      iconClass: "skoda",
      link: " https://otto-swidnik.skoda.pl/"
    },
    {
      id: "3",
      name: "Volkswagen",
      iconClass: "vw",
      link: "https://autohausotto.dealer.volkswagen.pl/"
    },
    {
      id: "4",
      name: "Samochody używane",
      iconClass: "used",
      link: "https://lublinuzywane.otomoto.pl/"
    },
    {
      id: "5",
      name: "Części samochodowe",
      iconClass: "parts",
      link: "https://eparts4you.pl/"
    },
 {
      id: "6",
      name: "Ubezpieczenia",
      iconClass: "ubezpieczenia",
      link: "https://eparts4you.pl/"
    } 
  ]

  return (
    <ul className={styles.list}>
      {dataArray.map(({ id, name, iconClass, link }) => {
        return (
          <ListItem
            changeActiveImg={changeActiveImg}
            id={id}
            name={name}
            iconClass={iconClass}
            link={link}
          />)
      })}
    </ul>
  );
}

import React, { useState } from "react";

import styles from "./App.module.scss";

import ImageContainer from "./containers/ImageContainer";
import ListContainer from "./containers/ListContainer";
import Modal from "./components/Modal";

function App() {
  const imgArray = [
    require("./img/audi-bg.png"),
    require("./img/seat-bg.png"),
    require("./img/skoda-bg.png"),
    require("./img/vw-bg.png"),
    require("./img/used-cars-bg.png"),
    require("./img/parts-bg.png"),
 require("./img/ubezpieczenia-bg.png"),
  ];
  const smokeImgArray = [
    require("./img/audi-bg-smoke.png"),
    require("./img/seat-bg-smoke.png"),
    require("./img/skoda-bg-smoke.png"),
    require("./img/vw-bg-smoke.png"),
    require("./img/used-cars-bg-smoke.png"),
    require("./img/parts-bg-smoke.png"),
  ];

  const [activeImg, changeActiveImg] = useState(0);
  const [blurActive, changeBlurActive] = useState(true);

  function setBlurInactive() {
    changeBlurActive(false);
  }

  return (
    <div className={styles.app}>
      <Modal setBlurInactive={setBlurInactive} />
      <ListContainer
        changeActiveImg={changeActiveImg}
        activeImg={smokeImgArray[activeImg]}
        blurActive={blurActive}
      ></ListContainer>
      <ImageContainer
        blurActive={blurActive}
        activeImg={imgArray[activeImg]}
      ></ImageContainer>
    </div>
  );
}

export default App;

import React from "react";

import styles from "./ImageContainer.module.scss";

export default function image({ activeImg, blurActive }) {
  return (
    <div
      className={`${styles.container} ${
        blurActive ? styles.blurActive : styles.BlurInactive
      }`}
    >
      <img className={styles.img} src={activeImg} alt="img" />
    </div>
  );
}

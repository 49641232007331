import React, { useState } from "react";

import styles from "./Modal.module.scss";
import modalImg from "../img/modal-img.png";

export default function Modal({ setBlurInactive }) {
  const [isModalOpen, setModalOpen] = useState(true);
  function closeModal() {
    setModalOpen(false);
    setBlurInactive();
  }

  function modalCountdown() {
    setTimeout(() => {
      closeModal();
    }, 7000);
  }

  modalCountdown();
  if (isModalOpen) {
    return (
      <div className={styles.container}>
        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
        <a href>
          <img src={modalImg} alt="" className={styles.img} />
        </a>
      </div>
    );
  } else {
    return null;
  }
}

import React from "react";

import styles from "./ListItem.module.scss";

export default function ListItem({ name, changeActiveImg, id, iconClass, link }) {

  return (
    <li
      onMouseEnter={() => {
        changeActiveImg(id);
      }}
      className={`${styles.item} ${styles[iconClass]}`}
    >
      <a href={link}>
        {name}
      </a>
    </li>
  );
}

import React from "react";

import styles from "./Logo.module.scss";
import logo from "../img/gg-v3-b-autorud.png";

export default function Logo() {
  return (
    <a href="http://ggauto.pl">
      <img className={styles.logo} src={logo} alt="logo" />
    </a>
  );
}

import React from "react";

import List from "../components/List";
import Logo from "../components/Logo";
import SmokeContainer from "../components/SmokeContainer";

import styles from "./ListContainer.module.scss";

export default function ListContainer({
  changeActiveImg,
  activeImg,
  blurActive,
}) {
  return (
    <div
      className={`${styles.container} ${
        blurActive ? styles.blurActive : styles.BlurInactive
      }`}
    >
      <div className={styles.topContainer}>
        <Logo />
      </div>
      <div className={styles.list}>
        <List changeActiveImg={changeActiveImg} />
      </div>
      <div className={styles.bottomContainer}>
        <SmokeContainer activeImg={activeImg} />
      </div>
    </div>
  );
}
